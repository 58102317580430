import React, { Component } from 'react';
import { ISceneConfig } from '../interfaces';
import { SceneEvents } from '../scenes/SceneEvents';

const description = `Find the 11 hidden letters throughout KF Gallery. Share the fun and get the product by visit here. The time limit is FIVE minutes.`;
const description_ment = `Let's find the HIDDEN LETTERS!`

interface Props {
  config: ISceneConfig,
}

export class InstructionsView extends Component<Props, {}> {
  constructor(props: Props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.props.config.eventBus.emit(SceneEvents.GoToTutorial);
  }

  render() {
    return (
      <div className='overlay-container intro'>
        <div className='text-wrapper'>
          <img src="assets/letters.png"></img>
          <div className='title'>Welcome to Cyrillic World!</div>
          <div className='description'>{description}
          <br></br>{description_ment}
          <br></br><br></br><a href='https://www.kf.or.kr/kf/ru/cnts.do?srchOrdtmOperAt=&srchTxt=&sysId=kf&langTy=KOR&resveMasterSn=11541&ongoingAt=&language=Korean&mi=1660' target="_blank">Click here to learn about more.</a>
          </div>
          <button type='button' onClick={this.onClick}>Tutorial</button>
        </div>
      </div>
    );
  }
}
