import React, { Component } from 'react';
import { ISceneConfig } from '../interfaces';

interface Props {
  config: ISceneConfig,
}

export class GameEndView extends Component<Props, {}> {
  constructor(props: Props) {
    super(props);
  }


  render() {
    return (
      <div className='overlay-container game-end'>
        <div className='text-wrapper'>
          <br></br>
          <br></br>
          <div className='title'>Unfortunately, you couldn't find all the <br></br>
            HIDDEN LETTERS!</div>
            <br></br>
          <div className='description'>If you want to try again, please press the button below. <br></br>
          Be successful next time and receive your <strong>PRIZE</strong>!</div>
          <br></br>
            <button type='button' onClick={() => location.reload()}>TRY AGAIN</button>
        </div>
      </div>
    );
  }
}
