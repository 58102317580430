import React, { Component } from 'react';
import { ISceneConfig } from '../interfaces';
import { SceneEvents } from '../scenes/SceneEvents';

interface Props {
  config: ISceneConfig,
}

export class TutorialView extends Component<Props, {}> {
  constructor(props: Props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.props.config.eventBus.emit(SceneEvents.StartGame);
  }

  render() {
    return (
      <div className='overlay-container tutorial'>
        <div className='text-wrapper'>
          <div className='title'>Find this LETTERS!</div>
          <div className='description'>Keep your eyes on the color. Press 'Get a hint' during the game.
          <br></br>Hints are given 5 times in total.</div>
          <img src="assets/list.png"></img>
          <button type='button' onClick={this.onClick}>Get Started</button>
        </div>
      </div>
    );
  }
}
